import firebase from "firebase/app"
import "firebase/firestore"

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBqu6ToOGlHVP_W9M9-gfr_E7cuZFn7syA",
  authDomain: "fundacionat-55cfe.firebaseapp.com",
  databaseURL: "https://fundacionat-55cfe.firebaseio.com/",
  projectId: "fundacionat-55cfe",
  storageBucket: "fundacionat-55cfe.appspot.com",
  messagingSenderId: "700008804071",
  appId: "1:700008804071:web:6877627e8084427d347c42",
  measurementId: "G-T6GE098QLK",
}
// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig)

export const db = fb.firestore()
